import React from "react"

import CallbackOverlayService from "../../../services/callback-overlay-service"
import pricingInfo from "../../pages/main/pricing/pricing-info"
import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"
import styles from "./callback.module.scss"

export function getModalTitle(success) {
  if (success) {
    return <Translate id={"form.thankYouHeaderCallback"}/>
  }

  const { action, block } = CallbackOverlayService._from || {}
  if (block === "pricing") {
    return getPricingHeaders(action)
  } else {
    //return <Translate id={"form.contactUs"}/>
  }
}

export function getModalDescription(success, registrationTokenSentToCustomer) {

  if (!success) {
    return (<div>{htmlStr()}<Translate id={"form.formMessageCallback"}/></div>)
  }
  return <Translate id={registrationTokenSentToCustomer ? "form.thankYouMessageCallback" : "form.thankYouMessage"}/>
}

function htmlStr() {
  let freeSummerMessage = "<span class='messageTitle'>Заказать обратный звонок</span><br/><br/>"
    //return  (<div dangerouslySetInnerHTML={{ __html: freeSummerMessage }} />)
  return (
    <div className={styles.messageTitle}>
      <div className={styles.title}>Заказать обратный<br/> звонок</div>
    </div>
  )
}

function getPricingHeaders(action) {
  return <LangContext.Consumer>
    {
      (lang) => {
        const { name } = pricingInfo(lang).find(info => info.code === action)
        return action !== "custom"
          ? <Translate>{translate => translate("form.pricingPlan")(name)}</Translate>
          : name
      }
    }
  </LangContext.Consumer>
}
