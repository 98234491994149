import React from "react"

import PresentationOverlayService from "../../../services/presentation-overlay-service"
import styles from "./presentation.module.scss"
import pricingInfo from "../../pages/main/pricing/pricing-info"
import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"

export function getModalTitle(success) {
  if (success) {
    return <Translate id={"form.thankYouHeaderCallback"} />
  }

  const { action, block } = PresentationOverlayService._from || {}
  if (block === "pricing") {
    return getPricingHeaders(action)
  } else {
  }
}

export function getModalDescription(success, registrationTokenSentToCustomer) {

  if (!success) {
    return (<div>{htmlStr()}</div>)
  }
  return <Translate id={registrationTokenSentToCustomer ? "form.thankYouMessagePresentation" : "form.thankYouMessage"} />
}

function htmlStr() {
  return (
    <div className={styles.head}>
      <div className={styles.title}>Заказать<br className="br-mobile" /> консультацию</div>
      <div className={styles.descr}>
        Заполните форму, и наш менеджер позвонит вам для согласования даты и времени.
      </div>
    </div>
  )
}

function getPricingHeaders(action) {
  return <LangContext.Consumer>
    {
      (lang) => {
        const { name } = pricingInfo(lang).find(info => info.code === action)
        return action !== "custom"
          ? <Translate>{translate => translate("form.pricingPlan")(name)}</Translate>
          : name
      }
    }
  </LangContext.Consumer>
}
